import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

export default () => {
  useEffect(() => {
    // Redirect to the UTM of billboard.
    navigate('/?utm_source=ooh&utm_medium=led_billboard&utm_campaign=c5_led');
  }, []);

  return <div />;
};
